import { useSpeechRecognition } from 'react-speech-kit';
import ic_send from '../assets/icons/ic_send.png'
import ic_cross from "../assets/icons/ic_cross.png";
import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle, useCallback, memo } from "react";
import { createUseStyles } from "react-jss";
import { ChatListButtonsBox, ChatListItem, InputBox } from '../components'
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const ChatList = (props, ref) => {

    let { onInputNewMessage, onSelectText, onSessionEndTrigger, onStopVideoAndVoice, chatIsClear, closechat } = props
    const style = useStyle()
    const [messageList, setMessageList] = useState([])
    const [isInputEnable, setIsInputEnable] = useState(true)
    const [width, setwidth] = useState(0)
    const listContainerRef = useRef()


    useEffect(() => {
        window.addEventListener("resize", update);
        update();
    }, [width])

    const onSessionEndCallback = useCallback(() => {
        onSessionEndTrigger()
    }, [])

    const update =()=> {
        setwidth(window.innerWidth)
    }

    const onSelectTextCallback = useCallback((text) => {
        // onSelectText(text)
        setIsInputEnable(true)
    }, [])

    useImperativeHandle(ref, () => ({
        addMessage: (message) => {
            setMessageList([...messageList, message])
            if (message?.button) {
                if (message?.button.length > 0) {
                    // console.log('buttons : ',message?.button)
                    // onShowButton()
                    setIsInputEnable(false)
                }
            }
            // console.log(message?.button)
        },
        clearChat: () => {
            setMessageList([])
        },
        scrollUp: () => {
            
            listContainerRef.current.scrollIntoView(
                {
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'nearest',
                })
        },
        inputEnable: () => {
            setIsInputEnable(true)
        },
        inputDisable: () => {
            setIsInputEnable(false)
        }
    }));

    useEffect(() => {
        if (ref.current) {
            listContainerRef.current.scrollIntoView(
                {
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'nearest',
                })
        }
    },
     [messageList])

    const onSelectButtonText = (text) => {
        setIsInputEnable(true)
        sendInputMessage(text)
    }

    const List = ({ data }) => {

        const listItems = data.map((item, index) =>
            // <ChatListItem messageListLength={messageList.length} item={item} index={index} onSelectText={onSelectTextCallback} />
            <ChatListItem onEndSession={onSessionEndCallback} messageListLength={messageList.length} item={item} index={index} onSelectText={(text) => onSelectButtonText(text)} />
        );

        return (
            <ul ref={listContainerRef} className={style.listContainer} id="listContainer">
                {listItems}
            </ul>
        );
    }

    const sendInputMessage = (msg) => {
        console.log(msg)
        if (msg !== '') {
            // setMessageList([...messageList, { button: [], isClient: true, text_eng: msg }])
            let newMsgList = messageList.concat({ button: [], isClient: true, text_eng: msg, msgTime: getDateTime() })
            setMessageList(newMsgList)

            // setText('')
            onInputNewMessage(msg)
        }
    }

    function getDateTime() {
        let date = new Date()
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var second = date.getSeconds();

        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;

        if (month.toString().length == 1) {
            month = '0' + month;
        }
        if (day.toString().length == 1) {
            day = '0' + day;
        }
        if (hours.toString().length == 1) {
            hours = '0' + hours;
        }
        if (minutes.toString().length == 1) {
            minutes = '0' + minutes;
        }
        if (second.toString().length == 1) {
            second = '0' + second;
        }

        var dateTime = day + '-' + month + '-' + year + ' ' + hours + ':' + minutes + ':' + second + ' ' + ampm;
        return dateTime;

    }
    return (
        <>
            <div id="mainContainer">
                <div className={style.mainListContainer}>
                    <List data={messageList} />
                </div>
                <InputBox isInputEnable={isInputEnable} onPressSendButton={(text) => { sendInputMessage(text) }} onStopVideoAndVoice={() => { onStopVideoAndVoice() }} />
            </div>
            {/* -------------------Full Screen Chat Dialog Starts---------------- */}

            {width <= 600 &&
            <Dialog
                fullScreen
                open={chatIsClear}
                onClose={chatIsClear} 
                className="dialog-container"
                // TransitionComponent={Transition}
            >
                <div id="mainContainerModal">
                    <div className={style.mainListContainer}>
                        <List data={messageList} />
                    </div>
                    <div className="modalbuttons">
                        <InputBox isInputEnable={isInputEnable} onPressSendButton={(text) => { sendInputMessage(text) }} onStopVideoAndVoice={() => { onStopVideoAndVoice() }} />
                        <div
                            className={style.chatImgButtonContainer}
                            id="chatImgButtonContainer"
                            onClick={closechat}
                            style={{marginRight: "5px"}}
                        >
                            <img
                                src={ic_cross}
                                className={style.chatImgButton}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
        } 

            {/* -------------------Full Screen Chat Dialog End---------------- */}

        </>
    );
}


const useStyle = createUseStyles({

    mainListContainer: {
        width: '100%',
        maxHeight: '90%',
        minHeight: '90%',
        overflow: 'auto',
        // height: 'inherit', 
        // display: 'block',
        scrollBehavior: 'smooth',
        flexDirection: 'column',
        flex: 1,
        // overflowX:'hidden',
        // overflowY:'hidden',
        '&::-webkit-scrollbar': {
            display: 'none',
            // background:'transparent',
            // width:0
        },
        '&::-webkit-scrollbar': {
            display: 'none',
            // background:'transparent',
            // width:0
        },
        msOverflowStyle: 'none'
    },
    listContainer: {
        display: '-ms-flexbox',
        flexDirection: 'column',
        listStyleType: 'none',
        padding: 0,
        margin: 0,
        paddingTop: '70vh',
    },

    chatitemLabelContainerChatBot: {
        maxWidth: '80%',
        // backgroundColor: '#429EFA',
        // borderTopLeftRadius: '5vw',
        // borderTopRightRadius: '5vw',
        // borderBottomRightRadius: '5vw',
        // borderBottomLeftRadius: '5vw',
        flexDirection: 'row',
        display: 'flex'
    },
    chatitemLabelContainerClient: {
        maxWidth: '80%',
        // backgroundColor: '#FFFFFF50',
        // borderTopLeftRadius: '5vw',
        // borderTopRightRadius: '5vw',
        // borderBottomRightRadius: '5vw',
        // borderBottomLeftRadius: '5vw',
        flexDirection: 'row',
        display: 'flex'

    },
    clientMsgLabel: {
        padding: '1vw',
        backgroundColor: '#FFFFFF50',
        borderTopLeftRadius: '5vw',
        borderTopRightRadius: '5vw',
        borderBottomRightRadius: '5vw',
        borderBottomLeftRadius: '5vw',
        fontSize: '0.9vw',
    },
    chatbotMsgLabel: {
        padding: '1vw',
        backgroundColor: '#429EFA',
        borderTopLeftRadius: '5vw',
        borderTopRightRadius: '5vw',
        borderBottomRightRadius: '5vw',
        borderBottomLeftRadius: '5vw',
        fontSize: '0.9vw',

    },
    emptyViewForClientItem: {
        flex: 1,

    },
    inputBox: {
        width: '100%',
        // height: '8%',
        marginTop: '1%',
        marginBottom: '1%',
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: 100,
    },
    inputFiled: {
        fontSize: '1vw',
        padding: '15px',
        backgroundColor: 'white',
        borderRadius: '5vw',
        marginLeft: '22px',
        borderColor: 'transparent',
        background: 'transparent',
        width: '100%',
        '&:hover': {

        },
        '&:active': {
            borderColor: 'white',
            borderWidth: 0
        },
    },
    sendButton: {

        // width: '2vw',
        // height: '2vw',

        // margin: '0.5vw',
        // padding: '0.5vw',

    },
    micContainer: {
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'transparent'
    },
    micButton: {
        width: '2.5vw',
        height: '2.5vw',
        marginRight: '0.5vw',
        marginLeft: '0.5vw',
        alignSelf: 'center',
        // background:'white',
        border: 0,
        background: 'transparent'

    },
    emptyPlaceHolderContainer: {
        width: '100%',
        height: '90%',
        backgroundColor: 'transparent',
        position: 'absolute',
        top: 0,
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    },
    emptyPlaceHolderInnerContainer: {
        width: '60%',
        height: '30%',
        backgroundColor: '#FFFFFF50',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        borderRadius: '50px',
        flexDirection: 'column'
    },
    buttonSayHello: {
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingTop: '3%',
        paddingBottom: '3%',
        color: 'white',
        backgroundColor: '#1A8DFF',
        borderRadius: '50px',
        borderWidth: 0
    },

    chatImgButtonContainer: {
        background: "white",
        padding: "12px",
        marginTop: "5px",
        borderRadius: 100,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        webkitBoxShadow: "1px 4px 4px #9E9E9E",
        mozBoxShadow: "1px 4px 4px #9E9E9E",
        boxShadow: "1px 4px 4px #9E9E9E",
    },
    chatImgButton: {
        width: "3vh",
        height: "3vh",
        objectFit: "contain",
        alignSelf: "center",
    },

})

export default memo(forwardRef(ChatList));
