// import { useSpeechSynthesis } from "react-speech-kit";
import ic_cross from "../src/assets/icons/ic_cross.png";
import ic_chat from "../src/assets/icons/ic_chat.png";
import ic_logout from "../src/assets/icons/ic_logout.png";
import ic_mic from "../src/assets/icons/ic_mic.png";
import 'bootstrap/dist/css/bootstrap.min.css';
import { v4 as uuidv4 } from "uuid";
import { Audio, Bars } from "react-loader-spinner";
import moment from "moment";
import useSpeechToText from "react-hook-speech-to-text";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";

import {
  VideoView,
  ChatList,
  SessionExpireModal,
  CompanyLogo,
  PolicyAgreementModal,
  SessionEndedModal,
} from "./components";
import Logo from "./components/Logo"
import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestMediaPermissions
} from 'mic-check';



import "./assets/styles/style.css";

import { useSpeechRecognition, useSpeechSynthesis  } from 'react-speech-kit';


const axios = require("axios");

var startTime = null;
var CHAT_SESSOIN = "";
var MIC_IS_RECOGNIZING = false;
var CHATBOT_IS_SPEAKING = false;
var CHAT_START_TIME = "";

var NAME = "";
var AGE = "";
var GENDER = "";
var MARITAL = "";
var DIAGNOSIS = "";

var CHAT_LIST = [];

const App = () => {



  const [value, setValue] = useState("");

  const { listen, listening, stop } = useSpeechRecognition({
    onResult: (result) => {
      setValue(result);
    },
  });

  // const { speak, cancel,  } = useSpeechSynthesis({
  //   onResult: (result) => {
  //     setValue(result);
  //   },
  // });

  const [isChatOrClear, setIsChatOrClear] = useState(true);
  const [havePermission, setPermission] = useState(false);
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [isMicEnable, setIsMicEnable] = useState(true);
  const style = useStyle({ isChatOrClear, isSessionExpired, isMicEnable });
  const videoViewRef = useRef();
  const chatListRef = useRef();
  const sessionExpireModalRef = useRef();
  const sessionEndedModalRef = useRef();
  const policyAgreementModalRef = useRef();

  const {
    error,
    interimResult,
    isRecording,
    results,
    startSpeechToText,
    stopSpeechToText,
  } = useSpeechToText({
    continuous: true,
    useLegacyResults: false,
  });

  const onInputNewMessageCallback = useCallback((newInputMsg) => {
    sendMessageToChatBot(newInputMsg);
  });

  const onPressNewSessionCallback = useCallback(() => {
    sessionExpireModalRef.current.hide();
    sessionExpire();

    setTimeout(() => {
      setIsChatOrClear(true);
      sendMessageToChatBot("hello");
    }, 1000);
  });

  const onPressSessionEndCallback = useCallback(() => {
    sessionEndedModalRef.current.hide();
    sessionExpire();

    setTimeout(() => {
      setIsChatOrClear(true);
      sendMessageToChatBot("hello");
    }, 1000);
  });

  const onPressLetsGoCallback = useCallback(() => {
    chatListRef.current.clearChat();
    sendMessageToChatBot("hello");

    // setOpen(true)

  }, []);

  const onSelectTextCallback = useCallback((text) => {
    let msg = {
      button: [],
      isClient: true,
      text_eng: text,
      msgTime: getDateTime(),
    };
    chatListRef.current.addMessage(msg);
    sendMessageToChatBot(text);
  }, []);

  const onSessionEndTriggerCallback = useCallback(() => {
    sendChatListToChatBot();
  }, []);

  const onStopVideoAndVoiceCallback = useCallback(() => {
    stopVideo();
    window.cancelSpeak();
  }, []);

  const clearSession = () => {

    stopVideo();
    window.cancelSpeak();
    startTime = "";
    CHAT_SESSOIN = "";
    CHAT_SESSOIN = uuidv4();
  };

  useEffect(() => {
    chatListRef.current.inputDisable();
    // setIsChatOrClear(true)
    if (window.performance) {
      if (performance.navigation.type == 1) {
        window.cancelSpeak();
        // alert( "This page is reloaded" );
      } else {
        // alert( "This page is not reloaded");
      }
    }
    policyAgreementModalRef.current.show();
  }, []);

  function checkPermissions() {

    navigator.getUserMedia(
      // constraints
      {
        video: true,
        audio: true
      },

      // successCallback
      function (localMediaStream) {
        var video = document.querySelector('video');
        video.src = window.URL.createObjectURL(localMediaStream);
        video.onloadedmetadata = function (e) {
          // Do something with the video here.
        };
      },

      // errorCallback
      function (err) {
        alert(err)
        //  if(err === PERMISSION_DENIED) {
        //    // Explain why you need permission and how to update the permission setting
        //  }
      }
    );
  }

  useEffect(() => {


    requestMediaPermissions()
      .then(() => {
        // can successfully access camera and microphone streams
        // DO SOMETHING HERE
      })

      .catch((err) => {
        const { type, name, message } = err;
        // if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
        //   // browser does not have permission to access camera or microphone
        // } else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
        //   // user didn't allow app to access camera or microphone
        // } else if (type === MediaPermissionsErrorType.CouldNotStartVideoSource) {
        //   // camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)
        //   // (mostly Windows specific problem)
        // } else {
        //   // not all error types are handled by this library
        // }
      });

    results.map((result, index) => {
      if (index === results.length - 1) {
        let msg = {
          button: [],
          isClient: true,
          text_eng: result?.transcript,
          msgTime: getDateTime(),
        };
        chatListRef.current.addMessage(msg);
        sendMessageToChatBot(result?.transcript);
      }
    });
  }, [results]);

  const playVideo = () => {
    videoViewRef.current.playVideo();
  };

  const stopVideo = () => {
    videoViewRef.current.stopVideo();
  };

  const speakByText = (text) => {
    window.speak(text, (isVoiceStated) => {
      debugger
      if (isVoiceStated) {
        playVideo();
        CHATBOT_IS_SPEAKING = true;
        stopSpeechToText();
      }
      if (!isVoiceStated) {
        stopVideo();
        CHATBOT_IS_SPEAKING = false;
        if (CHATBOT_IS_SPEAKING === false && MIC_IS_RECOGNIZING === true) {
          startSpeechToText();
          MIC_IS_RECOGNIZING = true;
        }
      }
    });
  };

  const checkTimeIsRemaining = (startTime) => {
    // start time and end time
    
    var endTime = moment(new Date().getTime());
    console.log("Start Time : ", startTime, " End Time : ", endTime);
    // calculate total duration
    var duration = moment.duration(endTime.diff(startTime));

    // duration in hours
    var hours = parseInt(duration.asHours());

    // duration in minutes
    var minutes = parseInt(duration.asMinutes()) % 60;

    console.log(hours, " hour and ", minutes, " minutes.");

    if (minutes > 9) {
      return false;
    }
    return true;
  };

  function getDateTime() {
    let date = new Date();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var second = date.getSeconds();

    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;

    if (month.toString().length == 1) {
      month = "0" + month;
    }
    if (day.toString().length == 1) {
      day = "0" + day;
    }
    if (hours.toString().length == 1) {
      hours = "0" + hours;
    }
    if (minutes.toString().length == 1) {
      minutes = "0" + minutes;
    }
    if (second.toString().length == 1) {
      second = "0" + second;
    }

    var dateTime =
      day +
      "-" +
      month +
      "-" +
      year +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      second +
      " " +
      ampm;
    return dateTime;
  }

  const toggleChatOrClearChat = () => {
    setIsChatOrClear(isChatOrClear => !isChatOrClear);
  };

  const sessionExpire = () => {
    setIsSessionExpired(false);
    clearSession();
    chatListRef.current.clearChat();
    setIsChatOrClear(false);
    chatListRef.current.inputEnable();
    setIsMicEnable(true);
    CHAT_START_TIME = "";
  };

  const toggleMic = () => {
    stopVideo();
    window.cancelSpeak();
    if (isRecording) {
      stopSpeechToText();
      MIC_IS_RECOGNIZING = false;
    } else {
      startSpeechToText();
      MIC_IS_RECOGNIZING = true;
    }
  };

  const speakByText2 = (text, response) => {
    
    if(text === "Greetings! What would you like me to call you?")
    {
    if (response)
      window.speak(text, (isVoiceStated) => {
          debugger
        if (isVoiceStated) {
          console.log("voice on hogai")
            playVideo();
            setTimeout(() => {
              stopVideo();
              setChatMsgs(response);
            },3000)
        }
 
      });
    }
    else{
      if (response){
      window.speak(text, (isVoiceStated) => {
          debugger
        if (isVoiceStated) {
          console.log("voice on hogai")
          playVideo();

          if(text === "Greetings! What would you like me to call you?")
          {
            setTimeout(() => {
              stopVideo();
              setChatMsgs(response);
            },5000)

          }

        }
        if (!isVoiceStated) {
          console.log("voice stop hogai")
          stopVideo();
          setChatMsgs(response);
        }
      });
    }
    }
  };

  let index = 0;
  let speachTime = 0;

  let speachText = "";

  const setChatMsgs = async (response) => {
    
    console.log("response : ", JSON.stringify(response));
    if (response.data?.text_eng.length > 1) {
      
      if (index <= response.data?.text_eng.length - 1) {
        
        chatListRef.current.inputDisable();
        setIsMicEnable(false);

        // setTimeout(async () => {

          speachText += response.data?.text_eng[index] + ",";

          let msg = {
            button: response.data?.button,
            isClient: false,
            text_eng: response.data?.text_eng[index],
            msgTime: getDateTime(),
          };
          chatListRef.current.addMessage(msg);
          startTime = new moment(new Date().getTime());
          setTimeout(() => {
            
            chatListRef.current.scrollUp();
          }, 500);

          if (index === response.data?.text_eng.length - 1) {
            
            chatListRef.current.inputEnable();
            setIsMicEnable(true);
          }
          if (index === response.data?.text_eng.length) {
            
            speakByText2(speachText, null);
            if (response.data?.button.length < 1) {
              chatListRef.current.inputEnable();
            }
          } else {
            
            speakByText2(response.data?.text_eng[index], response);
          }
          index++;
        // }, 1000);
      } else {
        index = 0;
        // speachTime = 0
      }
    } else {
      
      speakByText(response.data?.text_eng[0]);

      let msg = {
        button: response.data?.button,
        isClient: false,
        text_eng: response.data?.text_eng[0],
        msgTime: getDateTime(),
      };
      chatListRef.current.addMessage(msg);
      startTime = new moment(new Date().getTime());
      setTimeout(() => {
        
        chatListRef.current.scrollUp();
      }, 500);
      if (response.data?.button.length < 1) {
        
        chatListRef.current.inputEnable();
        setIsMicEnable(true);
      }

    }
  };


  const getCountCommasFromText = async (string) => {
    var count = 0;
    string.split(",").forEach(function (s) {
      count[s] ? count[s]++ : (count[s] = 1);
    });
    return count;
  };

  const getWordsCountByString = (str) => {
    return str.split(" ").length;
  };

  const checkForSaveDetails = (userPreviousMsg, newChatBotData) => {

    if (newChatBotData?.save_user_input !== "") {
      switch (newChatBotData?.save_user_input) {
        case "Name":
          NAME = userPreviousMsg;
          break;
        case "Age":
          AGE = userPreviousMsg;
          break;
        case "Pronoun":
          GENDER = userPreviousMsg;
          break;
        case "Marital":
          MARITAL = userPreviousMsg;
          break;
        case "Diagnosis":
          DIAGNOSIS = newChatBotData?.save_user_disgnosis;
          break;

        default:
          break;
      }
    }
  };

  const sendMessageToChatBot = (msg) => {
    // console.log('startTime', startTime)
    if (CHAT_SESSOIN === "") {
      // CHAT_SESSOIN = moment(new Date().getTime());
      CHAT_SESSOIN = uuidv4();
    }
    if (CHAT_START_TIME === "") {
      CHAT_START_TIME = getDateTime();
    }

    window.cancelSpeak();
    stopVideo();

    if (checkTimeIsRemaining(startTime)) {
      let userTime = getDateTime();
      try {
        axios
          .get(
            `https://chatbot.digitallandscape.com.pk:5020/get_bot_response?msg=${msg}&sessionId=${CHAT_SESSOIN}`
          )
          .then(function (response) {
            // console.log(response.data);
            if (response.data) {
              console.log(response?.data);
              setChatMsgs(response);
              CHAT_LIST.push({
                user_time: userTime,
                bot_time: getDateTime(),
                user: msg,
                bot: response.data?.text_eng,
              });
              checkForSaveDetails(msg, response?.data);
            }
            // setIsChatOrClear(true);

          })
          .catch(function (error) {
            console.log(error);
            alert(error.message);
          });
      } catch (error) {
        console.log("Error : ", error);
        alert(JSON.stringify(error));
      }
    } else {
      // setIsSessionExpired(true)
      sessionExpireModalRef.current.show();
    }
  };

  const sendChatListToChatBot = () => {

    let data = {
      uuid: CHAT_SESSOIN,
      conversation: CHAT_LIST,
      user_details: {
        start_time: CHAT_START_TIME,
        name: NAME,
        age: AGE,
        pronoun: GENDER,
        marital_staus: MARITAL,
        diagnosis: DIAGNOSIS,
      },
    };
    console.log("goodbye data", data)
    try {
      axios
        .post(
          `https://chatbot.digitallandscape.com.pk:5020/send_user_conversation`,
          data
        )
        .then(function (response) {

          console.log("goodbye response", response)

          if (response?.status === 200) {
            sessionExpire();
            CHAT_LIST = [];
            sessionEndedModalRef.current.show();
          }
        })
        .catch(function (error) {
          console.log(error);
          alert(error.message);
        });
    } catch (error) {
      console.log("Error : ", error);
      console.log("goodbye error", error)

      alert(JSON.stringify(error));
    }
  };

  return (
    <div className={style.mainContainer} id={`${isChatOrClear ? "blurContainer" : ""}`}>
      <VideoView ref={videoViewRef} id="myVideo"
        chatIsClear={isChatOrClear}
      />

      <div className="col-2">
        <div className="left-main-container">
          <div className="left-btns-container">
            <CompanyLogo />
            {/* <Logo /> */}
            <div className="infoMic">
              <span className="left-text1">Powered by
                <a className="left-text2" target="_blank" href="https://dls-global.com">Digital Landscape</a>
              </span>


              {!isChatOrClear &&

                <div
                  className={style.micButtonMobile}
                  id="micButtonMobile"
                  onClick={() => {
                    toggleMic();
                  }}
                >
                  {isRecording ? (
                    <Bars
                      heigth="100%"
                      width="100%"
                      color="#429EFA"
                      ariaLabel="loading"
                    />
                  ) : (
                    <img src={ic_mic} className={style.micButtonImage} />
                  )}
                </div>
              }
 {/* <div>
      <textarea
        value={value}
        onChange={(event) => setValue(event.target.value)}
      />
      <button onClick={() => speak({ text: value })}>Speak</button>
    </div> */}
              {/* <div>
                <textarea
                  value={value}
                  onChange={(event) => setValue(event.target.value)}
                />
                <button onMouseDown={listen} onMouseUp={stop}>
                  🎤
                </button>
                {listening && <div>Go ahead I'm listening</div>}
              </div> */}
            </div>
          </div>

        </div>


      </div>
      <div className="col-md-9 col-8">

        <div className="center-container">
          <div className={style.chatContainer} >
            <ChatList
              ref={chatListRef}
              chatIsClear={isChatOrClear}
              closechat={toggleChatOrClearChat}
              onInputNewMessage={onInputNewMessageCallback}
              onSelectText={onSelectTextCallback}
              onSessionEndTrigger={onSessionEndTriggerCallback}
              onStopVideoAndVoice={onStopVideoAndVoiceCallback}
            />
          </div>
        </div>
      </div>

      <div className="col-md-1 col-2">
        <div className={style.rightBtnContainer}>
          <div className={style.rightTopBtnContainer}>
            <div
              className={style.logoutButtonContainer}
              onClick={() => {
                sessionEndedModalRef.current.show();
              }}
            >
              <img src={ic_logout} className={style.logoutButtonImage} />
            </div>
          </div>
          <div className={style.rightBottomBtnContainer}>
            <div
              className={style.micButtonContainer}
              id="micButtonContainer"
              onClick={() => {
                toggleMic();
              }}
            >
              {isRecording ? (
                <Bars
                  heigth="100%"
                  width="100%"
                  color="#429EFA"
                  ariaLabel="loading"
                />
              ) : (
                <img src={ic_mic} className={style.micButtonImage} />
              )}
            </div>
            <div
              className={style.chatImgButtonContainer}
              onClick={toggleChatOrClearChat}
            >
              <img
                src={isChatOrClear ? ic_cross : ic_chat}
                className={style.chatImgButton}
              />
            </div>
          </div>
        </div>
      </div>


      <SessionExpireModal
        ref={sessionExpireModalRef}
        onPressNewSession={onPressNewSessionCallback}
      />
      <SessionEndedModal
        ref={sessionEndedModalRef}
        onPressNewSession={onPressSessionEndCallback}
      />
      <PolicyAgreementModal
        ref={policyAgreementModalRef}
        onPressLetsGo={onPressLetsGoCallback}
      />



    </div>
  );
};

const useStyle = createUseStyles({
  mainContainer: {
    display: "flex",
    top: "0%",
    left: "0%",
    height: "100%",
    width: "100%",
    overflow: "hidden",
    position: "absolute",
  },
  chatContainer: {
    // minWidth: '40%',
    // width: '40%',
    padding: "0 20px",
    width: (props) => (props.isChatOrClear ? "100%" : "0%"),
    // display: (props) => (props.isChatOrClear ? "absolute" : "relative"),
    height: "100vh",
    // backgroundColor: 'red',
    // overflow: "hidden",
    // top: 0,
    // right: (props) => props.isChatOrClear ? '6%' : '-50%',
    // borderRadius: "2vh",
    // transition: "right 10s ease-in-out",
    // transition: "visibility 1s, opacity 0.9s",
    // visibility: (props) => props.isChatOrClear ? 'visible' : 'hidden',
    // opacity: (props) => props.isChatOrClear ? 1 : 0
    // transition: "width 2s, opacity 0.8s",
    opacity: (props) => (props.isChatOrClear ? 1 : 0),
    display: "flex",
    justifyContent: "end",
  },
  rightBtnContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    minWidth: "15%",
    paddingRight: "10px"

  },
  rightTopBtnContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end"
  },
  rightBottomBtnContainer: {
    marginBottom: "30px",
    marginLeft: "10px"
  },
  logoutButtonContainer: {
    marginTop: "10px",
    background: "white",
    padding: "1.6vh",
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    webkitBoxShadow: "1px 4px 4px #9E9E9E",
    mozBoxShadow: "1px 4px 4px #9E9E9E",
    boxShadow: "1px 4px 4px #9E9E9E",
  },
  micButtonMobile: {
    width: "6.5vh",
    height: "6.5vh",
    background: "white",
    padding: "1vh",
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: (props) => (props.isMicEnable ? "auto" : "none"),
    backgroundColor: (props) => (props.isMicEnable ? "white" : "#00000010"),
    cursor: "pointer",
    webkitBoxShadow: "1px 4px 4px #9E9E9E",
    mozBoxShadow: "1px 4px 4px #9E9E9E",
    boxShadow: "1px 4px 4px #9E9E9E",
  },
  micButtonContainer: {
    width: "6.5vh",
    height: "6.5vh",
    background: "white",
    padding: "1vh",
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: (props) => (props.isMicEnable ? "auto" : "none"),
    backgroundColor: (props) => (props.isMicEnable ? "white" : "#00000010"),
    cursor: "pointer",
    webkitBoxShadow: "1px 4px 4px #9E9E9E",
    mozBoxShadow: "1px 4px 4px #9E9E9E",
    boxShadow: "1px 4px 4px #9E9E9E",
  },
  brainButtonContainer: {
    background: "white",
    // padding: '1%',
    borderRadius: "100%",
    top: "2%",
    left: "1%",
    position: "absolute",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  chatImgButtonContainer: {
    background: "white",
    padding: "1.8vh",
    marginTop: "5px",
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    webkitBoxShadow: "1px 4px 4px #9E9E9E",
    mozBoxShadow: "1px 4px 4px #9E9E9E",
    boxShadow: "1px 4px 4px #9E9E9E",
  },
  chatImgButton: {
    width: "3vh",
    height: "3vh",
    objectFit: "contain",
    alignSelf: "center",
  },
  logoutButtonImage: {
    width: "3.5vh",
    height: "3.5vh",
    objectFit: "contain",
    alignSelf: "center",
  },
  micButtonImage: {
    width: "3.5vh",
    height: "3.5vh",
    objectFit: "contain",
    alignSelf: "center",
  },
  brainButtonImage: {
    width: "6.5vh",
    height: "6.5vh",
    objectFit: "contain",
    alignSelf: "center",
  },
  chatExpiryScreen: {
    width: "100vw",
    height: "100vh",
    backgroundColor: "transparent",
    position: "absolute",
    left: 0,
    zIndex: 12000,
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    transition: "top 0.5s ease-in-out",
    top: (props) => (props.isSessionExpired ? 0 : "-100%"),
  },
  chatExpiryPopupView: {
    width: "20%",
    height: "30%",
    backgroundColor: "white",
    alignSelf: "center",
    borderRadius: "8%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  sessionModalHeader: {
    textAlign: "center",
  },
  sessionModalDesc: {
    textAlign: "center",
    color: "#00000050",
  },
  sessionModalButton: {
    width: "12vh",
    height: "4vh",
    color: "white",
    alignSelf: "center",
    backgroundColor: "black",
    borderColor: "transparent",
    borderRadius: 100,
  },
  brainButtonImage: {
    width: "7vh",
    height: "7vh",
    objectFit: "contain",
    alignSelf: "center",
  },
});

export default App;
