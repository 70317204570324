import ic_brain from '../assets/icons/ic_brain.png'

import { Button, Input, Image } from "antd";
import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle, useCallback, memo } from "react";
import { createUseStyles } from "react-jss";


const Logo = ({ }) => {

    const [isMouseOver, setIsMouseOver] = useState(false)

    const style = useStyle({ isMouseOver })



    useEffect(() => {

    }, [])


    return (
        <>
            {/* <div className={style.brainButtonContainer} onMouseOver={() => { setIsMouseOver(true) }} onMouseOut={() => { setIsMouseOver(false) }}  >
                <img src={ic_brain} className={style.brainButtonImage} />
            </div>
            <div className={style.overViewContainer} onClick={() => { }} >

                <div className={style.overViewInnerContainer} >
                    <text style={{ fontSize: '1.6vh', color: '#429EFA', fontWeight: 'bold' }}>Virtual</text>
                    <text style={{ fontSize: '1.6vh', color: 'black', fontWeight: 'bold' }}>Psychologist</text>
                </div>
            </div> */}
            <div class="logo-container">
                <div class="icon">
                    <img src={ic_brain} />
                </div>
              
                    <span>
                    Virtual  
                    <small>padding</small>

                    </span>

            </div>

        </>
    );
}


const useStyle = createUseStyles({
    brainButtonContainer: {
        background: '#FFFFFF30',
        // padding: '1%',
        borderRadius: '100%',
        // top: '1.5vh',
        // left: '1.5vw',
        // position: 'absolute',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'space-between',
        // zIndex: 1000,
        cursor: 'pointer',
        webkitBoxShadow: "1px 4px 4px #9E9E9E",
        mozBoxShadow: "1px 4px 4px #9E9E9E",
        boxShadow: "1px 4px 4px #9E9E9E",
        marginLeft: "12px",
        marginTop: "10px",

    },
    brainButtonImage: {
        width: '7vh',
        height: '7vh',
        objectFit: 'contain',
        alignSelf: 'center'
    },
    overViewContainer: {
        width: '20vh',
        height: '7vh',
        background: '#FFFFFF30',
        borderRadius: 100,
        top: '1.2vh',
        left: (props) => props.isMouseOver ? '1.5vw' : '-50vw',
        position: 'absolute',
        justifyContent: 'flex-end',
        display: 'flex',
        alignItems: 'center',
        transition: "left 1s ease-in-out",
        flexDirection: 'row',
    },
    overViewInnerContainer: {
        width: '15vh',
        height: '6.5vh',
        background: 'transparent',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        transition: "left 0.5s ease-in-out",
        flexDirection: 'column',
        borderRadius: 100,

    }
})

export default memo(Logo);
